import { Text } from "@clipboard-health/ui-react";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { LinkCard } from "@src/appV2/lib/Links/LinkCard";

import { SKILLS_ASSESSMENT_BASE_PATH } from "../paths";
import { VideoRecordingIcon } from "./VideoRecordingIcon";

interface Props {
  title: string;
}

export function AssessmentBanner(props: Props) {
  const { title } = props;

  return (
    <LinkCard
      to={{
        pathname: SKILLS_ASSESSMENT_BASE_PATH,
      }}
      title={
        <Text bold variant="body2" marginLeft={1}>
          {title}
        </Text>
      }
      subtitle={null}
      variant="info"
      leadingIcon={<VideoRecordingIcon sx={{ fontSize: "50px" }} />}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_MY_ACCOUNT_BANNER_CLICKED, { title });
      }}
    />
  );
}
