import { formatDate } from "@clipboard-health/date-time";
import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";

import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { VideoRecordingIcon } from "../VideoRecordingIcon";

interface Props {
  retakeAllowedAfter?: string;
}
export function SkillsAssessmentStatusFailedAsync(props: Props) {
  const { retakeAllowedAfter } = props;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();

  return (
    <Stack spacing={4} alignItems="center">
      <VideoRecordingIcon sx={{ fontSize: "64px" }} />

      <Text sx={{ lineHeight: "24px" }}>
        Unfortunately your submission didn&apos;t meet the requirements for passing this assessment.
      </Text>

      <Text sx={{ lineHeight: "24px" }}>
        You&apos;ll have the opportunity to retake the assessment{" "}
        {isDefined(retakeAllowedAfter) ? `on ${formatDate(retakeAllowedAfter)}` : ""}. We&apos;ll
        send you a reminder to complete the assessment at that time. You&apos;ll still be able to
        work any shifts that you&apos;ve already booked. Additional information on the assessment
        can be found{" "}
        <ExternalLink to={skillsAssessmentConfig.supportArticleLink}>here</ExternalLink>.
      </Text>
    </Stack>
  );
}
