import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { VideoRecordingIcon } from "../VideoRecordingIcon";

interface Props {
  qualification?: string;
}
export function SkillsAssessmentStatusPassedAsync(props: Props) {
  const { qualification } = props;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();

  return (
    <Stack spacing={4} alignItems="center">
      <VideoRecordingIcon sx={{ fontSize: "64px" }} />

      <Text sx={{ lineHeight: "24px" }}>
        Congratulations, you&apos;ve passed the assessment! By taking the assessment you&apos;ve
        ensured that high performing {qualification ?? "professional"}s like yourself have access to
        the shifts you want to work. You won&apos;t need to take this again.
      </Text>

      <Text sx={{ lineHeight: "24px" }}>
        Additional information on the assessment can be found{" "}
        <ExternalLink to={skillsAssessmentConfig.supportArticleLink}>here</ExternalLink>.
      </Text>
    </Stack>
  );
}
