import { Stack } from "@mui/material";

import {
  AssessmentType,
  type SkillsAssessment,
  type SkillsAssessmentStatus as SkillsAssessmentStatusType,
  SkillsAssessmentWorkerStatus,
} from "../../types";
import { checkIsWorkerAllowedToScheduleAssessment } from "../../utils/checkIsWorkerAllowedToScheduleAssessment";
import { ScheduleAssessment } from "./ScheduleAssessment";
import { SkillsAssessmentStatusFailedAsync } from "./StatusFailedAsync";
import { SkillsAssessmentStatusFailedScheduled } from "./StatusFailedScheduled";
import { SkillsAssessmentStatusPassedAsync } from "./StatusPassedAsync";
import { SkillsAssessmentStatusPassedScheduled } from "./StatusPassedScheduled";
import { SkillsAssessmentStatusScheduled } from "./StatusScheduled";
import { SkillsAssessmentStatusUnderReviewAsync } from "./StatusUnderReviewAsync";
import { SkillsAssessmentStatusUnderReviewScheduled } from "./StatusUnderReviewScheduled";

interface Props {
  assessment: SkillsAssessment;
  assessmentStatus: SkillsAssessmentStatusType;
  assessmentScheduledFor?: string;
  qualification?: string;
  dueDate?: string;
  onSchedule: () => void;
  onReschedule: () => void;
}

export function SkillsAssessmentStatus(props: Props) {
  const {
    assessmentStatus,
    qualification,
    assessment,
    assessmentScheduledFor,
    dueDate,
    onSchedule,
    onReschedule,
  } = props;
  const isSchedulingAllowed = checkIsWorkerAllowedToScheduleAssessment({
    retakeAllowedAfter: assessmentStatus.attributes.retakeAllowedAfter,
    assessmentType: assessmentStatus.attributes.type,
    assessmentStatus: assessmentStatus.attributes.status,
    assessmentScheduledFor,
  });

  return (
    <Stack width="100%" height="100%">
      {isSchedulingAllowed ? (
        <ScheduleAssessment
          assessment={assessment}
          dueDate={dueDate}
          qualification={qualification}
          onSuccess={onSchedule}
        />
      ) : (
        <>
          {assessmentStatus.attributes.status === SkillsAssessmentWorkerStatus.PENDING &&
            assessmentStatus.attributes.type === AssessmentType.SCHEDULED && (
              <SkillsAssessmentStatusScheduled
                assessment={assessment}
                assessmentStatus={assessmentStatus}
                scheduledFor={assessmentScheduledFor!}
                qualification={qualification}
                dueDate={dueDate}
                onReschedule={onReschedule}
              />
            )}
          {[
            SkillsAssessmentWorkerStatus.UNDER_REVIEW,
            SkillsAssessmentWorkerStatus.SUBMITTED,
          ].includes(assessmentStatus.attributes.status) &&
            (assessmentStatus.attributes.type === AssessmentType.SCHEDULED ? (
              <SkillsAssessmentStatusUnderReviewScheduled />
            ) : (
              <SkillsAssessmentStatusUnderReviewAsync qualification={qualification} />
            ))}
          {/* we know that the retake date is after today, because otherwise the worker see the video flow screen, not the status  */}
          {assessmentStatus.attributes.status === SkillsAssessmentWorkerStatus.FAILED &&
            (assessmentStatus.attributes.type === AssessmentType.SCHEDULED ? (
              <SkillsAssessmentStatusFailedScheduled
                retakeAllowedAfter={assessmentStatus.attributes.retakeAllowedAfter}
              />
            ) : (
              <SkillsAssessmentStatusFailedAsync
                retakeAllowedAfter={assessmentStatus.attributes.retakeAllowedAfter}
              />
            ))}
          {assessmentStatus.attributes.status === SkillsAssessmentWorkerStatus.PASSED &&
            (assessmentStatus.attributes.type === AssessmentType.SCHEDULED ? (
              <SkillsAssessmentStatusPassedScheduled qualification={qualification} />
            ) : (
              <SkillsAssessmentStatusPassedAsync qualification={qualification} />
            ))}
        </>
      )}
    </Stack>
  );
}
