import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

interface AssessmentErrorDialogProps {
  onOk: () => void;
  modalState: UseModalState;
}

export function AssessmentErrorDialog(props: AssessmentErrorDialogProps) {
  const { onOk, modalState } = props;

  return (
    <CommonDialog
      modalState={modalState}
      actions={
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onOk();
          }}
        >
          OK
        </Button>
      }
      title={
        <Title component="h3" sx={{ fontWeight: 600 }}>
          We&apos;ve experienced an error
        </Title>
      }
    >
      <Text sx={{ lineHeight: "24px", textAlign: "center" }}>
        There&apos;s been an error with the skills assessment. Please contact support and we&apos;ll
        resolve this issue for you.
      </Text>
    </CommonDialog>
  );
}
