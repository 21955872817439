import { ExternalLink, Li, LoadingButton, Text, Title, Ul } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addHours, differenceInHours, parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { useState } from "react";

import { useScheduleAssessment } from "../../api/useScheduleAssessment";
import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { type SkillsAssessment, type SkillsAssessmentStatus } from "../../types";
import { checkIsWorkerAllowedToRescheduleAssessment } from "../../utils/checkIsWorkerAllowedToRescheduleAssessment";
import { DateTimePicker } from "../DateTimePicker";

interface Props {
  assessment: SkillsAssessment;
  assessmentStatus: SkillsAssessmentStatus;
  scheduledFor: string;
  dueDate?: string;
  qualification?: string;
  onReschedule?: () => void;
}
export function SkillsAssessmentStatusScheduled(props: Props) {
  const {
    assessment,
    assessmentStatus,
    scheduledFor,
    dueDate,
    qualification,
    onReschedule = () => undefined,
  } = props;

  const { skillsAssessmentConfig, scheduledAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const {
    minimumLeadTimeToScheduleAssessmentInHours,
    maximumLeadTimeToScheduleAssessmentInHours,
    rescheduleLeadTimeInMinutes,
  } = scheduledAssessmentConfig;

  const isAllowedToReschedule = checkIsWorkerAllowedToRescheduleAssessment({
    assessmentType: assessmentStatus.attributes.type,
    rescheduleLeadTimeInMinutes,
    assessmentScheduledFor: scheduledFor,
    assessmentStatus: assessmentStatus.attributes.status,
  });

  // date time picker uses null instead of undefined
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [rescheduledFor, setRescheduledFor] = useState<Date | null>(null);

  const worker = useDefinedWorker();
  const { mutateAsync: scheduleAssessment, isLoading } = useScheduleAssessment();

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack spacing={4}>
        <Title component="h1">
          You&apos;ve booked an assessment for{" "}
          <b>
            {format(parseISO(scheduledFor), "MMMM do")} at{" "}
            {format(parseISO(scheduledFor), "h:mmaaa z")}
          </b>
          . Return here at that time to take it.
        </Title>
        <Ul>
          <Li>
            <Text bold sx={{ lineHeight: "24px" }}>
              You need to pass the assessment{" "}
              {isDefined(dueDate) ? `by ${format(parseISO(scheduledFor), "MMMM do")}` : ""} to
              continue booking shifts.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              You&apos;ll answer questions pertaining to your knowledge and experience
              {isDefined(qualification) ? ` as a ${qualification}` : ""} over video.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              The assessment takes about{" "}
              {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes} minutes.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              Tap <ExternalLink to={skillsAssessmentConfig.supportArticleLink}>here</ExternalLink>{" "}
              for additional details.
            </Text>
          </Li>
        </Ul>
        {isAllowedToReschedule && (
          <Stack spacing={2}>
            <Text sx={{ lineHeight: "24px" }}>
              To reschedule the call you can adjust the below date to a time of your choice. You can
              reschedule up to {rescheduleLeadTimeInMinutes} minutes before your call.
            </Text>

            <DateTimePicker
              minDateTime={addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours)}
              maxDateTime={addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours)}
              errorText={`Time selected must be at least ${minimumLeadTimeToScheduleAssessmentInHours} hours in the future and at most ${
                maximumLeadTimeToScheduleAssessmentInHours / 24
              } days in the future`}
              disabled={isLoading}
              value={rescheduledFor}
              minutesStep={5}
              onChange={setRescheduledFor}
            />
          </Stack>
        )}
      </Stack>
      {isAllowedToReschedule && (
        <LoadingButton
          variant="contained"
          disabled={isLoading || !isDefined(rescheduledFor)}
          isLoading={isLoading}
          onClick={async () => {
            await scheduleAssessment({
              assessmentId: assessment.id,
              workerId: worker.userId,
              scheduledFor: rescheduledFor!.toISOString(),
            });
            logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_RESCHEDULED, {
              assessmentId: assessment.id,
              workerId: worker.userId,
              originalScheduledFor: scheduledFor,
              newScheduledFor: rescheduledFor!.toISOString(),
              hoursUntilOriginalScheduledFor: differenceInHours(parseISO(scheduledFor), new Date()),
              hoursUntilNewScheduledFor: differenceInHours(rescheduledFor!, new Date()),
            });

            onReschedule();
          }}
        >
          Reschedule
        </LoadingButton>
      )}
    </Stack>
  );
}
