import { ExternalLink, Li, Text, Title, Ul } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";

interface Props {
  qualification?: string;
}
export function SkillsAssessmentStatusPassedScheduled(props: Props) {
  const { qualification } = props;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();

  return (
    <Stack spacing={4}>
      <Title component="h1">Congratulations, you&apos;ve passed the assessment!</Title>
      <Ul>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            By taking the assessment you&apos;ve ensured that high performing {qualification}s like
            yourself have access to the shifts you want to work.
          </Text>
        </Li>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>You won&apos;t need to take this again.</Text>
        </Li>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            Tap <ExternalLink to={skillsAssessmentConfig.supportArticleLink}>here</ExternalLink> to
            view additional details about the assessment.
          </Text>
        </Li>
      </Ul>
    </Stack>
  );
}
