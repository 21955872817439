import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { VideoRecordingIcon } from "../VideoRecordingIcon";

interface Props {
  qualification?: string;
}
export function SkillsAssessmentStatusUnderReviewAsync(props: Props) {
  const { qualification } = props;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();

  return (
    <Stack spacing={4} alignItems="center">
      <VideoRecordingIcon sx={{ fontSize: "64px" }} />

      <Text sx={{ lineHeight: "24px" }}>
        We&apos;re grading the video assessment you&apos;ve shared with us and will be done within 1
        business day. If you passed the assessment you&apos;ll be able to book shifts at that time.
      </Text>

      <Text sx={{ lineHeight: "24px" }}>
        Clipboard requires this assessment to ensure that the best {qualification ?? "professional"}
        s have access to shifts. Additional information on the assessment can be found{" "}
        <ExternalLink to={skillsAssessmentConfig.supportArticleLink}>here</ExternalLink>.
      </Text>
    </Stack>
  );
}
