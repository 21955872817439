import { formatDate } from "@clipboard-health/date-time";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import LaunchIcon from "@mui/icons-material/Launch";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

interface Props {
  onDetailsClick: () => void;
  onOk: () => void;
  modalState: UseModalState;
  retakeAllowedAfter?: string;
}

export function AssessmentFailedScheduledDialog(props: Props) {
  const { onDetailsClick, onOk, modalState, retakeAllowedAfter } = props;

  return (
    <CommonDialog
      modalState={modalState}
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              onOk();
            }}
          >
            OK
          </Button>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<LaunchIcon />}
            onClick={() => {
              onDetailsClick();
            }}
          >
            DETAILS
          </Button>
        </>
      }
      title={
        <Title component="h3" sx={{ fontWeight: 600 }}>
          You did not pass the assessment
        </Title>
      }
    >
      <Text sx={{ lineHeight: "24px", textAlign: "center" }}>
        Unfortunately your submission didn&apos;t meet the requirements for passing this assessment.
      </Text>
      <br />
      <Text sx={{ lineHeight: "24px", textAlign: "center" }}>
        You&apos;ll have the opportunity to schedule another assessment
        {retakeAllowedAfter ? ` on ${formatDate(retakeAllowedAfter)}` : ""}. You&apos;ll still be
        able to work any shifts that you&apos;ve already booked.
      </Text>
    </CommonDialog>
  );
}
