import { ExternalLink, Li, LoadingButton, Text, Title, Ul } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addHours, differenceInHours, format, parseISO } from "date-fns";
import { useState } from "react";

import { useScheduleAssessment } from "../../api/useScheduleAssessment";
import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { type SkillsAssessment } from "../../types";
import { DateTimePicker } from "../DateTimePicker";

interface Props {
  assessment: SkillsAssessment;
  dueDate?: string;
  qualification?: string;
  onSuccess?: () => void;
}

export function ScheduleAssessment(props: Props) {
  const { assessment, dueDate, qualification, onSuccess = () => undefined } = props;

  const { skillsAssessmentConfig, scheduledAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const { minimumLeadTimeToScheduleAssessmentInHours, maximumLeadTimeToScheduleAssessmentInHours } =
    scheduledAssessmentConfig;

  // date time picker uses null instead of undefined
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [scheduledFor, setScheduledFor] = useState<Date | null>(null);

  const worker = useDefinedWorker();
  const { mutateAsync: scheduleAssessment, isLoading } = useScheduleAssessment();

  return (
    <Stack justifyContent="space-between" width="100%" height="100%">
      <Stack spacing={4}>
        <Title component="h1">
          Schedule your assessment and return here at that time to take it.
        </Title>
        <Ul>
          <Li>
            <Text bold sx={{ lineHeight: "24px" }}>
              You need to complete this assessment to continue booking shifts.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              It takes about {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes}{" "}
              minutes and must be completed{" "}
              {isDefined(dueDate) ? `by ${format(parseISO(dueDate), "MMMM do")}` : ""} for you to
              continue booking shifts.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              You&apos;ll answer questions pertaining to your knowledge and experience
              {isDefined(qualification) ? ` as a ${qualification}` : ""} over video.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              Tap <ExternalLink to={skillsAssessmentConfig.supportArticleLink}>here</ExternalLink>{" "}
              for additional details.
            </Text>
          </Li>
        </Ul>
        <Stack spacing={3}>
          {isDefined(scheduledFor) ? (
            <Text sx={{ lineHeight: "24px" }}>You are scheduling your assessment for:</Text>
          ) : (
            <Text sx={{ lineHeight: "24px" }}>Book your assessment for the following time:</Text>
          )}
          <DateTimePicker
            minDateTime={addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours)}
            maxDateTime={addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours)}
            errorText={`Time selected must be at least ${minimumLeadTimeToScheduleAssessmentInHours} hours in the future and at most ${
              maximumLeadTimeToScheduleAssessmentInHours / 24
            } days in the future`}
            disabled={isLoading}
            value={scheduledFor}
            minutesStep={5}
            onChange={setScheduledFor}
          />
        </Stack>
      </Stack>
      <LoadingButton
        variant="contained"
        disabled={isLoading || !isDefined(scheduledFor)}
        isLoading={isLoading}
        onClick={async () => {
          await scheduleAssessment({
            assessmentId: assessment.id,
            workerId: worker.userId,
            scheduledFor: scheduledFor!.toISOString(),
          });
          logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_EVENT, {
            assessmentId: assessment.id,
            workerId: worker.userId,
            scheduledFor: scheduledFor!.toISOString(),
            hoursUntilScheduled: differenceInHours(scheduledFor!, new Date()),
          });

          onSuccess();
        }}
      >
        Confirm
      </LoadingButton>
    </Stack>
  );
}
