import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

import { type SkillsAssessment } from "../types";

interface Props {
  assessment: SkillsAssessment;
  onConfirm: () => void;
  modalState: UseModalState;
}

export function ExitRecordingConfirmationDialog(props: Props) {
  const { assessment, onConfirm, modalState } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title={
        <Title component="h3" sx={{ fontWeight: 600 }}>
          Are you sure you want to exit?
        </Title>
      }
      actions={
        <Stack width="100%" spacing={1}>
          <Button variant="contained" onClick={onConfirm}>
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={async () => {
              modalState.closeModal();
            }}
          >
            Cancel
          </Button>
        </Stack>
      }
    >
      <Text sx={{ lineHeight: "24px" }} textAlign="center">
        You&apos;ll need to complete the skills assessment to book a shift. The assessment takes{" "}
        {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes} minutes to complete.
      </Text>
    </CommonDialog>
  );
}
