import { isDefined } from "@clipboard-health/util-ts";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";

interface Props {
  // date time picker uses null instead of undefined
  // eslint-disable-next-line @typescript-eslint/ban-types
  value: Date | null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: (newValue: Date | null) => void;
  minDateTime?: Date;
  maxDateTime?: Date;
  errorText?: string;
  minutesStep?: number;
  disabled?: boolean;
}

export function DateTimePicker(props: Props) {
  const {
    value,
    onChange,
    disabled = false,
    minDateTime,
    maxDateTime,
    errorText,
    minutesStep,
  } = props;
  const [hasError, setHasError] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDateTimePicker
        ampmInClock
        disablePast
        value={value}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
        minutesStep={minutesStep}
        disabled={disabled}
        slotProps={{
          toolbar: {
            hidden: true,
          },
          textField: {
            placeholder: "Select a time",
            helperText: hasError ? errorText : "",
            required: true,
          },
        }}
        onError={(error) => {
          setHasError(isDefined(error));
        }}
        onAccept={(newValue) => {
          if (!hasError) {
            onChange(newValue);
          }
        }}
      />
    </LocalizationProvider>
  );
}
