import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import LaunchIcon from "@mui/icons-material/Launch";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

interface Props {
  onDetailsClick: () => void;
  onContinue: () => void;
  modalState: UseModalState;
}

export function AssessmentUnderReviewScheduledDialog(props: Props) {
  const { onDetailsClick, onContinue, modalState } = props;

  return (
    <CommonDialog
      modalState={modalState}
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              onContinue();
            }}
          >
            CONTINUE
          </Button>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<LaunchIcon />}
            onClick={() => {
              onDetailsClick();
            }}
          >
            DETAILS
          </Button>
        </>
      }
      title={
        <Title component="h3" sx={{ fontWeight: 600 }}>
          Your assessment is being reviewed
        </Title>
      }
    >
      <Text sx={{ lineHeight: "24px", textAlign: "center" }}>
        Your assessment is currently being reviewed. We&apos;ll share the results with you within 1
        business day. Continue for more details.
      </Text>
    </CommonDialog>
  );
}
